<mat-card class="flex flex-col min-w-70 sm:ml-2 w-100-p border-none shadow-none" (click)="select()">
	<div class="flex flex-row py-4" [ngClass]="{'bg-default': isSelected()}">
		@if (isSelected()) {
			<div class="flex flex-col px-4 justify-center">
				<mat-icon class="icon-size-10 text-blue-500"> check_circle </mat-icon>
			</div>
		} @else {
			<div class="flex flex-col px-4 justify-center"></div>
		}

		<div class="flex flex-col w-11/12">
			<div class="text-lg font-large">
				{{ resistanceExercise().name }}

				<mat-chip class="bg-default text-md font-normal">
					{{ resistanceExercise().resistanceTypeId | enumDescription:resistanceTypeDescriptions }}
				</mat-chip>
			</div>
			<div class="flex flex-col sm:flex-row sm:items-center -ml-0.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
				<ng-container>
					<div class="flex items-center">
						<div class="ml-1.5 text-md text-secondary">
							{{ resistanceExercise().description }}
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="flex flex-col justify-center w-1/12"></div>
	</div>
</mat-card>
