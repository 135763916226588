import { Subject } from 'rxjs';

import { KeyValuePipe } from '@angular/common';
import { Component, inject, input, OnDestroy, OnInit, output, signal, viewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResistanceExerciseResponse } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.response-model';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';
import { ResistanceType, ResistanceTypeDescriptions } from '@fitness-central/shared/enum/resistance-type.enum';

import { TrainingExerciseCardComponent } from '../components/card/card.component';

@Component
(
	{
		selector: 'training-exercise-create-sidebar',
		templateUrl: './create.component.html',
		encapsulation: ViewEncapsulation.None,
		imports:
		[
			FormsModule,
			KeyValuePipe,
			MatIconModule,
			MatAutocompleteModule,
			MatButtonModule,
			MatFormFieldModule,
			MatInputModule,
			MatOptionModule,
			MatProgressBarModule,
			MatProgressSpinnerModule,
			MatTooltipModule,
			MatSelectModule,
			ReactiveFormsModule,
			SidebarComponent,
			TrainingExerciseCardComponent
		]
	}
)

export class TrainingRoutineResistanceExerciseCreateSidebarComponent implements OnInit, OnDestroy
{
	private readonly _formBuilder = inject(FormBuilder);

	public routineId = input<string>();
	public exercises = input<ResistanceExerciseResponse[]>();

	readonly onCreate = output<ResistanceExerciseResponse>();
	readonly onCancel = output();

	public selectedExerciseId: number = 0;

	public formGroupDirective = viewChild(FormGroupDirective);
	public createForm: FormGroup;

	public resistanceTypes = ResistanceType;
	public resistanceTypeDescriptions = ResistanceTypeDescriptions;

	public filteredExercises = signal<ResistanceExerciseResponse[]>([]);

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit(): void
	{
		this.buildForm();
		this.buildExerciseList();
		this.isLoading.set(false);
	}

	public create()
	{
		this.createForm.disable();

		this.isLoading.set(false);

		this.resetForm();

		const selectedExercise = this.exercises().find(x => x.resistanceExerciseId == this.selectedExerciseId);

		this.onCreate.emit(selectedExercise);
	}

	public cancel()
	{
		this.resetForm();

		this.onCancel.emit();
	}

	public select(resistanceExerciseId: number): void
	{
		this.selectedExerciseId = resistanceExerciseId;
		this.createForm.controls['resistanceExerciseId'].setValue(resistanceExerciseId);
	}

	private buildForm()
	{
		this.createForm = this._formBuilder.group
		(
			{
				resistanceExerciseId: [0, [Validators.required, Validators.min(1)]],
				resistanceExerciseName: [''],
				resistanceType: [ResistanceType.All.toString()]
			}
		);
	}

	public buildExerciseList(): void
	{
		const sortedExercises = this.exercises().sort
		(
			(a, b) =>
			{
				if (a.resistanceExerciseId === this.selectedExerciseId)
				{
					return -1;
				}

				if (b.resistanceExerciseId === this.selectedExerciseId)
				{
					return 1;
				}

				return a.name.localeCompare(b.name);
			}
		);

		this.filteredExercises.set(sortedExercises);
	}

	public filterExercises(): void
	{
		this.select(0);

		const filterValue = this.createForm.controls['resistanceExerciseName'].value.toLowerCase();

		let filteredExercises = this.exercises().filter
		(
			exercise => exercise.name.toLowerCase().includes(filterValue)
		);

		const resistanceType = this.createForm.controls['resistanceType'].value;

		if (resistanceType != 0)
		{
			filteredExercises = filteredExercises.filter(exercise => exercise.resistanceTypeId == resistanceType);
		}

		this.filteredExercises.set(filteredExercises);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
				this.filterExercises()
			}
		);
	}
}
