import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';
import { NgClass } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ResistanceExerciseResponse } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.response-model';
import { ResistanceType, ResistanceTypeDescriptions } from '@fitness-central/shared/enum/resistance-type.enum';

@Component
(
	{
		selector: 'training-exercise-card',
		templateUrl: './card.component.html',
		imports: [
			NgClass,
			EnumDescriptionPipe,
			MatCardModule,
			MatChipsModule,
			MatIconModule
		]
	}
)

export class TrainingExerciseCardComponent
{
	public resistanceExercise = input<ResistanceExerciseResponse>();
	public selectedExerciseId = input<number>();

	readonly onSelect = output<number>();

	public resistanceType: typeof ResistanceType = ResistanceType;
	public resistanceTypeDescriptions = ResistanceTypeDescriptions;

	public isSelected = computed<boolean>(() => this.selectedExerciseId() == this.resistanceExercise().resistanceExerciseId);


	public select(): void
	{
		this.onSelect.emit(this.resistanceExercise().resistanceExerciseId);
	}
}
