export enum ResistanceType
{
	All = 0,
	BodyWeight = 1,
	Barbell = 2,
	Dumbbell = 3,
	Machine = 4
}

export const ResistanceTypeDescriptions: Record<ResistanceType, string> = {
	[ResistanceType.All]: 'All',
	[ResistanceType.BodyWeight]: 'BodyWeight',
	[ResistanceType.Barbell]: 'Barbell',
	[ResistanceType.Dumbbell]: 'Dumbbell',
	[ResistanceType.Machine]: 'Machine'
};
