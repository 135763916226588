<ui-sidebar [title]="'Edit Exercise'" [isFormValid]="editForm?.valid" [isFormDisabled]="editForm?.disabled" (onCancel)="cancel()" (onSave)="edit()">
	<ng-container fixed-content>
		<div class="relative flex flex-col">
			<div class="w-full max-w-3xl">
				<form [formGroup]="editForm">
					<div class="flex flex-col space-y-4 my-4">
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full px-6">
								<mat-label>Resistance Type</mat-label>
								<mat-select matInput formControlName="resistanceType" (selectionChange)="filterExercises()">
									@for (resistanceType of resistanceTypeDescriptions | keyvalue; track resistanceType) {
										<mat-option [value]="resistanceType.key">
											{{ resistanceType.value }}
										</mat-option>
									}
								</mat-select>
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full px-6">
								<mat-label>Filter by Name</mat-label>
								<input type="text" matInput formControlName="resistanceExerciseName" (keyup)="filterExercises()" />
							</mat-form-field>
						</div>
					</div>
				</form>
			</div>
		</div>
	</ng-container>

	<ng-container content>
		<div class="relative flex flex-col flex-auto items-center">
			<div class="w-full max-w-3xl mt-4">
				<div class="flex flex-col space-y-4">
					@for (resistanceExercise of filteredExercises; track resistanceExercise) {
						<training-exercise-card [resistanceExercise]="resistanceExercise" [selectedExerciseId]="selectedExerciseId" (onSelect)="select($event)">
						</training-exercise-card>
					}
				</div>
			</div>
		</div>
	</ng-container>
</ui-sidebar>
